<script setup lang="ts"></script>

<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-textarea label="Label" placeholder="Type your text" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-textarea
          label="With maxlength and hint text"
          placeholder="Type your text"
          hint="Message goes here"
          maxlength="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
