<script setup lang="ts">
import { ref } from "vue";
let icons;
if (window.iconToClass) {
  icons = Object.keys(window.iconToClass).sort();
} else {
  icons = ["my-alan", "my-ed-connect", "my-nsw-gov", "my-mma"];
}

const state = ref({
  transparent: false,
  size: "40px",
  sizes: [
    "16px",
    "24px",
    "32px",
    "40px",
    "48px",
    "56px",
    "62px",
    "70px",
    "78px",
    "84px",
    "92px",
  ],
  icon: "my-nsw-gov",
  icons: icons,
  showAll: false,
});
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <ads-app-icon
          app-name="my-aust-curric"
          :transparent="state.transparent"
          :size="state.size"
        />
      </v-col>
      <v-col>
        <ads-app-icon
          app-name="my-alan"
          :transparent="state.transparent"
          :size="state.sizes[4]"
        />
      </v-col>
      <v-col>
        <ads-app-icon
          app-name="my-cese"
          :transparent="state.transparent"
          :size="state.sizes[5]"
        />
      </v-col>
      <v-col>
        <ads-app-icon
          app-name="my-dls"
          :transparent="state.transparent"
          :size="state.sizes[6]"
        />
      </v-col>
      <v-col>
        <ads-app-icon
          app-name="my-edu-chat"
          :transparent="state.transparent"
          :size="state.sizes[7]"
        />
      </v-col>
      <v-col>
        <ads-app-icon
          app-name="my-seia-resources"
          :transparent="state.transparent"
          :size="state.sizes[8]"
        />
      </v-col>
      <v-col>
        <ads-app-icon
          app-name="my-tele-counselling"
          :transparent="state.transparent"
          :size="state.sizes[9]"
        />
      </v-col>
      <v-col>
        <ads-app-icon
          app-name="my-staff-noticeboard"
          :transparent="state.transparent"
          :size="state.sizes[10]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
