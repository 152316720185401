
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><h1>Your first ADS 3 app</h1>
<p>Build your new app using ADS 3 is easy:</p>
<ol>
<li>Clone the <code class="">doe-ui-template-v3</code> <a href="https://bitbucket.org/nsw-education/doe-ui-template-v3">repository</a> to your local machine.</li>
<li>Follow the instructions in the included <code class="">README.md</code> to get started.</li>
</ol>
<p>Details about the template project, such as its pre-configurations, can be found in the <code class="">Template Project</code> section.</p>
<ul>
<li>If you want to set up multiple views/pages for your app, refer to the <code class="">/router</code> directory which is for Vue Router. Please refer to <a href="https://router.vuejs.org/">Vue Router documentation</a> for details.</li>
<li>In most cases, ADS 3 uses Vuetify components directly (e.g. v-btn, v-select), so when you use a component, its details such as <code class="">props</code>, <code class="">events</code>, <code class="">slots</code>, etc. can be found on the <a href="https://vuetifyjs.com/en/components/all/#containment">Vuetify documentation</a>. This site lists the ADS enhancements for Vuetify components in the <code class="">Components</code> section. The main purpose of Ads 3 enhancements is to meet ADS branding and design.</li>
<li>If you need to use state management in your app, the template projects has <a href="https://pinia.vuejs.org/">Pinia</a> pre-installed, and you can refer to the <code class="">store</code> directory and set up your central store.</li>
<li>Vue 3 supports both <code class="">options</code> API and <code class="">composition</code> API, meaning you can code your components in two ways. <code class="">composition</code> API is recommended by Vue and has better TypeScript support. If you used React <code class="">v16.8+</code>, you will find that the concepts of <code class="">composition</code> API are similar to React <code class="">hooks</code>.</li>
<li>You can add new colours / themes to suit your project’s needs, please refer to the <code class="">Colour Palette</code> and <code class="">Technical Specification</code> sections for examples.</li>
</ul>
<div class="mt-10">
<h1>Deployment</h1>
</div>
<p>Normally you will just need the <code class="">build</code> script in the <code class="">package.json</code> file (we recommend to use <code class="">pnpm</code> as the package manager) to compile and build your app for production. Please see the <code class="">package.json/scripts</code> section. The default building tool for Vue 3 is <a href="https://vitejs.dev/"><code class="">Vite</code></a>, a much better and faster tool than <code class="">webpack</code>. Vite has many build options, and you can follow <a href="https://vitejs.dev/config/build-options.html">the documentation</a> and configure it in your project’s <code class="">vite.config.ts</code> file.</p>
</div>
</template>
