<script setup lang="ts">
import { ref } from "vue";

const selected = ref([]);

const headers = [
  { title: "Name", key: "name" },
  { title: "Received", key: "received" },
  { title: "Scholastic year", key: "scholasticYear" },
  { title: "Teacher", key: "teacher" },
  { title: "Status", key: "status" },
];

const items = [
  {
    name: "Josephine, Helen Peterson Montgomery",
    received: "07 Apr 2023",
    scholasticYear: "Year 1",
    teacher: "Jeremy Bell",
    status: "Approved",
    color: "success",
  },
  {
    name: "Ayan Brewer",
    received: "05 Apr 2023",
    scholasticYear: "Year 1",
    teacher: "Adan Roberson",
    status: "Declined",
    color: "error",
  },
  {
    name: "Jaime Hines",
    received: "02 Apr 2023",
    scholasticYear: "Year 1",
    teacher: "Nora Good",
    status: "Approved",
    color: "success",
  },
  {
    name: "Cody Wagner",
    received: "08 Apr 2023",
    scholasticYear: "Year 1",
    teacher: "Flynn Reese",
    status: "Received",
    color: "warning",
  },
  {
    name: "Someone Else",
    received: "09 Apr 2023",
    scholasticYear: "Year 1",
    teacher: "Another Person",
    status: "Draft",
  },
];
</script>

<template>
  <v-container>
    <v-card class="rounded-lg pa-4">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        item-value="name"
        items-per-page="5"
        return-object
        show-select
      >
        <template #item.scholasticYear="{ item }">
          {{ item.scholasticYear }}
        </template>
        <template #item.status="{ item }">
          <v-chip :color="item.color">
            {{ item.status }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
