<script setup lang="ts">
import { aliases } from "vuetify/iconsets/mdi";
import { AdsIcons } from "@nswdoe/doe-ui-core-v3";
import { computed } from "vue";

defineOptions({
  name: "Icons",
});

const vuetifyIconsObject = Object.keys(aliases)
  .map((alias) => ({
    alias,
    name: aliases[alias],
  }))
  .sort((a, b) => a.alias.localeCompare(b.alias));

const adsIconsObject = Object.keys(AdsIcons)
  .map((alias: string) => ({
    alias,
    name: AdsIcons[alias].name,
    override: vuetifyIconsObject.find((i) => i.alias === alias)?.name ?? "",
  }))
  .sort((a, b) => a.alias.localeCompare(b.alias));

function isOverridden(alias: string) {
  return adsIconsObject.map((a) => a.alias).includes(alias);
}

const filteredVuetifyIconsObject = computed(() => {
  return vuetifyIconsObject.filter((i) => !isOverridden(i.alias));
});
</script>

<template>
  <div class="markdown-body">
    <h2>Using icons in your project</h2>
    <p>Icons are added using the <code>v-icon</code> component:</p>

    <div class="component-example">
      <v-row>
        <v-col>
          <v-icon icon="$calendar" />
        </v-col>
        <v-col>
          <code>&lt;v-icon icon="<mark>$calendar</mark>" /&gt;</code>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-icon icon="$error" />
        </v-col>
        <v-col>
          <code>&lt;v-icon icon="<mark>$error</mark>" /&gt;</code>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-icon icon="$warning" />
        </v-col>
        <v-col>
          <code>&lt;v-icon icon="<mark>$warning</mark>" /&gt;</code>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-icon icon="$edit" />
        </v-col>
        <v-col>
          <code>&lt;v-icon icon="<mark>$edit</mark>" /&gt;</code>
        </v-col>
      </v-row>
    </div>

    <h2>ADS icon aliases</h2>

    <table>
      <thead>
        <tr>
          <th>Alias</th>
          <th>Name</th>

          <th>Overridden Vuetify icon</th>

          <th class="text-center">Preview</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="icon in adsIconsObject" :key="icon.name">
          <td>
            <strong>${{ icon.alias }}</strong>
          </td>

          <td>
            <code>{{ icon.name }}</code>
          </td>

          <td>
            <template v-if="icon.override">
              <code>{{ icon.override }}</code>
            </template>
          </td>

          <td class="text-center">
            <v-icon :icon="'$' + icon.alias" color="medium-emphasis" />
          </td>
        </tr>
      </tbody>
    </table>
    <h2>Vuetify built-in aliases</h2>
    <p>(Excluding any aliases overridden by ADS)</p>

    <table>
      <thead>
        <tr>
          <th>Alias</th>
          <th>Name</th>
          <th class="text-center">Preview</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="icon in filteredVuetifyIconsObject"
          :key="icon.name as string"
        >
          <td>
            <strong>${{ icon.alias }}</strong>
          </td>

          <td>
            <code>{{ icon.name }}</code>
          </td>

          <td class="text-center">
            <v-icon :icon="'$' + icon.alias" color="medium-emphasis" />
          </td>
        </tr>
      </tbody>
    </table>

    <h2>Extending and overriding aliases</h2>
    <p>
      Follow
      <a
        href="https://vuetifyjs.com/en/features/icon-fonts/#mdi-js-svg"
        target="_blank"
        >the Vuetify documentation</a
      >
      for how to import additional icons from
      <a href="https://www.npmjs.com/package/@mdi/js" target="_blank"
        >@mdi/js</a
      >
      into your project, either within your SFC template, or added to your
      aliases (in your
      <a
        href="https://bitbucket.org/nsw-education/doe-ui-template-v3/src/master/src/plugins/vuetify.ts"
        target="_blank"
        >src/plugins/vuetify.ts</a
      >
      config) for global reusability.
    </p>
  </div>
</template>
