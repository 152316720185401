<script setup lang="ts">
import { exampleNavigationDrawerProps } from "./ExampleNavigationDrawer";
import { computed, useAttrs } from "vue";

defineOptions({
  name: "ExampleNavigationDrawer",
});

const props = defineProps(exampleNavigationDrawerProps);
const attrs = useAttrs();
const emit = defineEmits(["update:modelValue"]);

const drawer = computed({
  get() {
    return attrs.modelValue as boolean;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <v-navigation-drawer
    v-bind="$attrs"
    v-model="drawer"
    :width="props.width"
    :color="props.color"
    expand-on-hover
    class="mt-2"
    mobile-breakpoint="sm"
  >
    <v-list>
      <template v-for="item in items">
        <v-list-item
          v-if="!item.items"
          :key="`${item.title}-single`"
          :title="item.title"
          :prepend-icon="item.icon"
          :to="item.route"
          :href="item.href"
          :target="item.target"
          :exact="!!item.exact"
          @click="item.clickHandler ? item.clickHandler() : () => {}"
        />
        <v-list-group v-else :key="`${item.title}-parent`" :value="item.title">
          <template #activator="{ props: activate }">
            <v-list-item
              v-bind="activate"
              :title="item.title"
              :prepend-icon="item.icon"
            />
          </template>

          <template v-for="subItem in item.items">
            <v-list-item
              v-if="subItem.route"
              :key="subItem.title"
              :value="subItem.title"
              :title="subItem.title"
              :to="subItem.route"
              :href="subItem.href"
              :target="subItem.target"
              :exact="!!subItem.exact"
              @click="subItem.clickHandler ? subItem.clickHandler() : () => {}"
            />
            <v-list-subheader v-else :key="subItem.title + 'subheader'">
              {{ subItem.title }}
            </v-list-subheader>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
