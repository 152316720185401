<script lang="ts"></script>

<template>
  <v-layout style="overflow: visible">
    <v-app-bar>
      <template #prepend>
        <v-btn icon>
          <v-icon>$close</v-icon>
        </v-btn>
      </template>

      <v-app-bar-title class="ml-2 text-white">
        <h3>Page title</h3>
      </v-app-bar-title>
    </v-app-bar>
  </v-layout>
</template>
