export const headerData = {
  title: "Page title",
  subtitle:
    "Page subtitle goes here. Short description about this page and what the user can do here.",
  iconSize: "40px",
  sizeItems: [
    { text: "Small (25px)", value: "25px" },
    { text: "Default (50px)", value: "50px" },
    { text: "Large (75px)", value: "75px" },
  ],
  backgroundImages: [
    { text: "Light", value: "../banner/banner-light.png" },
    { text: "Dark", value: "../banner/banner-dark.png" },
  ],
};
