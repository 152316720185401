<script setup lang="ts"></script>

<template>
  <v-container>
    <v-row>
      <v-col cols="4"> disabled </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-slider disabled />
      </v-col>
    </v-row>
  </v-container>
</template>
