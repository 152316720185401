<script setup lang="ts">
function rules(v: string) {
  if (v.length <= 10) {
    return true;
  } else {
    return "Max 10 characters";
  }
}
</script>

<template>
  <v-container>
    <!--    1. disabled-->
    <v-row>
      <v-col cols="6">
        <v-textarea label="Disabled" placeholder="Type your text" disabled />
      </v-col>
    </v-row>

    <!--    2. error-->
    <v-row>
      <v-col cols="6">
        <v-textarea label="Error" placeholder="Type your text" error />
      </v-col>
    </v-row>

    <!--    3. max 10 characters-->
    <v-row>
      <v-col cols="6">
        <v-textarea
          label="Max 10 characters with validation"
          placeholder="Type your text"
          counter
          :rules="[rules]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
