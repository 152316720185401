
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Vuetify 3 provides several <a href="https://vuetifyjs.com/en/features/application-layout/">application layouts</a> for developers to choose from, making it easier to create responsive and visually appealing web applications. In ADS 3, the recommended application layout includes an app bar, navigation drawer, main content area, and is already set up in the <a href="https://bitbucket.org/nsw-education/doe-ui-template-v3">template project</a>, as shown below.</p>
<img src="../images/22.png" width="1000">
<p>Please refer to the file <a href="https://bitbucket.org/nsw-education/doe-ui-template-v3/src/master/src/App.vue">https://bitbucket.org/nsw-education/doe-ui-template-v3/src/master/src/App.vue</a></p>
</div>
</template>
