
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';
import withoutIcon from './withoutIcon.vue';
import withoutSubtitle from './withoutSubtitle.vue';
import showRightPanel from './showRightPanel.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<div class="font-weight-light">Communicating important information to the user.</div>
<div class="mt-3">
<h2>Basic usage</h2>
<p>The banner component can have a default background, a user-specified background, or no background.</p>
</div>
<div class="component-example">
    <basic-usage></basic-usage>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsHeader" demo-file="basicUsage"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-10">
<h2>Without icon</h2>
</div>
<div class="component-example">
    <without-icon></without-icon>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsHeader" demo-file="withoutIcon"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-10">
<h2>Without subtitle</h2>
</div>
<div class="component-example">
    <without-subtitle></without-subtitle>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsHeader" demo-file="withoutSubtitle"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-10">
<h2>Props</h2>
</div>
<p><highlightjs language="javascript" code="export const adsHeaderProps = {
icon: {
  type: String,
  default: null
},
iconSize: {
  type: String,
  default: &#x27;50&#x27;
},
iconColor: {
 type: String
},
title: {
  type: String,
  default: &#x27;&#x27;
},
subtitle: {
  type: String,
  default: &#x27;&#x27;
},
defaultBackgroundLight: {
  type: Boolean,
  default: false
},
defaultBackgroundDark: {
  type: Boolean,
  default: false
},
backgroundPositionLeft: {
  type: Boolean,
  default: false
},
backgroundColor: {
  type: String,
  default: null
},
backgroundPositionRight: {
  type: Boolean,
  default: false
},
centered: {
  type: Boolean,
  default: false
},
backgroundImageUrl: {
  type: String,
  default: null
},
contentClass: {
  type: String,
  default: &#x27;pl-6&#x27;
}} as const;"></highlightjs></p>
</div>
</template>
