interface Color {
  hex: string;
  hex_r: string;
  hex_g: string;
  hex_b: string;
  rgb_r: number;
  rgb_g: number;
  rgb_b: number;
  tmp_r: number;
  tmp_g: number;
  tmp_b: number;
  srgb_r: number;
  srgb_g: number;
  srgb_b: number;
  lum_r: number;
  lum_g: number;
  lum_b: number;
  lum: number;
}

export const calculateContrastRatioAntecedent = (
  hex1: string,
  hex2: string,
): number => {
  const color1 = prepColor(hex1);
  const color2 = prepColor(hex2);

  const antecedent =
    (Math.max(color1.lum, color2.lum) + 0.05) /
    (Math.min(color1.lum, color2.lum) + 0.05);

  return antecedent;
};

const prepColor = (hex: string): Color => {
  const color: Color = {
    hex: hex,
    hex_r: hex.substring(1, 3),
    hex_g: hex.substring(3, 5),
    hex_b: hex.substring(5, 7),
    rgb_r: 0,
    rgb_g: 0,
    rgb_b: 0,
    tmp_r: 0,
    tmp_g: 0,
    tmp_b: 0,
    srgb_r: 0,
    srgb_g: 0,
    srgb_b: 0,
    lum_r: 0,
    lum_g: 0,
    lum_b: 0,
    lum: 0,
  };

  color.rgb_r = parseInt(color.hex_r, 16);
  color.rgb_g = parseInt(color.hex_g, 16);
  color.rgb_b = parseInt(color.hex_b, 16);

  color.tmp_r = color.rgb_r / 255;
  color.tmp_g = color.rgb_g / 255;
  color.tmp_b = color.rgb_b / 255;

  color.srgb_r =
    color.tmp_r <= 0.03928
      ? color.tmp_r / 12.92
      : Math.pow((color.tmp_r + 0.055) / 1.055, 2.4);

  color.srgb_g =
    color.tmp_g <= 0.03928
      ? color.tmp_g / 12.92
      : Math.pow((color.tmp_g + 0.055) / 1.055, 2.4);

  color.srgb_b =
    color.tmp_b <= 0.03928
      ? color.tmp_b / 12.92
      : Math.pow((color.tmp_b + 0.055) / 1.055, 2.4);

  color.lum_r = 0.2126 * color.srgb_r;
  color.lum_g = 0.7152 * color.srgb_g;
  color.lum_b = 0.0722 * color.srgb_b;

  color.lum = color.lum_r + color.lum_g + color.lum_b;

  return color;
};
