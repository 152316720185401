
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';
import codeSnippet from './codeSnippet.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<p><code class="">v-navigation-drawer</code> examples can be seen on the <a href="https://vuetifyjs.com/en/components/navigation-drawers">official Vuetify documentation</a>. The component’s API such as <code class="">props</code>, <code class="">events</code> and <code class="">slots</code> can be found on the <a href="https://vuetifyjs.com/en/api/v-navigation-drawer/">API document</a> of <a href="https://vuetifyjs.com/en/api/v-navigation-drawer/"><code class="">v-navigation-drawer</code></a>.</p>
<p>The navigation drawer component is configured to have the following defaults:</p>
<ul>
<li><code class="">railWidth</code> is set to <code class="">80</code>.</li>
<li><code class="">scrim</code>: is set to <code class="">true</code> to enable background.</li>
<li><code class="">mobileBreakpoint</code>: is set to <code class="">lg</code>.</li>
<li><code class="">nav</code> for <code class="">VList</code> used inside <code class="">VNavigationDrawer</code> is set to <code class="">true</code>, as per to the <a href="https://vuetifyjs.com/en/api/v-list/#props-nav"><code class="">VList</code> document</a>.</li>
</ul>
<div class="mt-3">
<h2>Basic usage</h2>
</div>
<div class="component-example">
    <basic-usage></basic-usage>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsNavigationDrawer" demo-file="basicUsage" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-3">
<h2>Code Snippet</h2>
</div>
<p>Below is an example of the component used for this site. The full code, including the definition of types, props, and data, can be seen here:</p>
<p><a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsNavigationDrawer/docs/examples/ExampleNavigationDrawer.vue">https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsNavigationDrawer/docs/examples/ExampleNavigationDrawer.vue</a></p>
<p><a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsNavigationDrawer/docs/examples/ExampleNavigationDrawer.ts">https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsNavigationDrawer/docs/examples/ExampleNavigationDrawer.ts</a></p>
<div class="component-example">
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsNavigationDrawer" demo-file="codeSnippet" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
</div>
</template>
