<script setup lang="ts"></script>

<template>
  <v-container>
    <v-responsive>
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <v-btn color="primary" size="x-small"> Extra small Button </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn color="primary" size="small"> Small Button </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn color="primary"> Regular Button </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn color="primary" size="large"> Large Button </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn color="primary" size="x-large"> X-Large Button </v-btn>
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>
