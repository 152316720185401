<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Grey</h3>
      </v-col>
      <v-col>
        <v-chip> Grey </v-chip>
      </v-col>
      <v-col> </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Info</h3>
      </v-col>
      <v-col>
        <v-chip color="info"> Info </v-chip>
      </v-col>
      <v-col>
        <code>color="info"</code>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Error</h3>
      </v-col>
      <v-col>
        <v-chip color="error"> Error </v-chip>
      </v-col>
      <v-col>
        <code>color="error"</code>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Warning</h3>
      </v-col>
      <v-col>
        <v-chip color="warning"> Warning </v-chip>
      </v-col>
      <v-col>
        <code>color="warning"</code>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Success</h3>
      </v-col>
      <v-col>
        <v-chip color="success"> Success </v-chip>
      </v-col>
      <v-col>
        <code>color="success"</code>
      </v-col>
    </v-row>
  </v-container>
</template>
