<script setup lang="ts">
import { ref } from "vue";

const state = ref({
  versionNo: "Demo-test",
  environment: "yellow-1.1.0",
  isVisible: true,
});

function hide() {
  state.value.isVisible = false;
}
</script>

<template>
  <v-container>
    <ads-environment-info
      :version-no="state.versionNo"
      :environment="state.environment"
      :is-visible="state.isVisible"
      @hide="hide"
    />
  </v-container>
</template>
