<script setup lang="ts"></script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-checkbox label="Label" />
      </v-col>
    </v-row>
  </v-container>
</template>
