
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './examples/ExampleUsage.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<div class="font-weight-light">Provide critical information to the users that may require decisions about a specific task.</div>
<p>The component’s API such as <code class="">props</code>, <code class="">events</code> and <code class="">slots</code> can be found on the <a href="https://vuetifyjs.com/en/api/v-dialog/">API document of <code class="">v-dialog</code></a>.</p>
<div class="mt-3">
<h2>Code Snippet</h2>
</div>
<p>Below is an example of using <code class="">ads-menu</code> and other components to create a classic user profile menu. Full code including definition of type, props and data can be seen here:</p>
<p><a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsDialog/docs/examples//ExampleDialog.vue">https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsDialog/docs/examples//ExampleDialog.vue</a></p>
<p><a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsDialog/docs/examples/ExampleDialog.ts">https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsDialog/docs/examples/ExampleDialog.ts</a></p>
<p><a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsDialog/docs/examples/ExampleUsage.vue">https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsDialog/docs/examples/ExampleUsage.vue</a></p>
<div class="component-example">
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsDialog" demo-file="basicUsage" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
</div>
</template>
