
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import info from './info.vue';
import warning from './warning.vue';
import error from './error.vue';
import success from './success.vue';

import basicExample from './basicExample.vue';
import typeExamples from './typeExamples.vue';
import closableExample from './closableExample.vue';
import customTextExample from './customTextExample.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<div class="font-weight-light">Convey small pieces of information such as a status or property.</div>
<p>The component’s API such as <code class="">props</code>, <code class="">events</code> and <code class="">slots</code> can be found on the <a href="https://vuetifyjs.com/en/api/v-alert/">API document of <code class="">v-alert</code></a>.</p>
<h2>Basic example</h2>
<div class="component-example">
    <basic-example></basic-example>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsAlert" demo-file="basicExample" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<h2>Available types</h2>
<div class="component-example">
    <type-examples></type-examples>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsAlert" demo-file="typeExamples" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<h2>Closable example</h2>
<div class="component-example">
    <closable-example></closable-example>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsAlert" demo-file="closableExample" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<h2>Slotted custom text</h2>
<div class="component-example">
    <custom-text-example></custom-text-example>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsAlert" demo-file="customTextExample" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
</div>
</template>
