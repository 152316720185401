<script setup lang="ts">
import { ref } from "vue";

const items = ref(["Foo", "Bar", "Fizz", "Buzz"]);
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select :items="items" label="Standard" />
      </v-col>

      <v-col>
        <v-select :items="items" label="Multiple" multiple />
      </v-col>

      <v-col>
        <v-select :items="items" label="Chips" multiple chips />
      </v-col>
    </v-row>
  </v-container>
</template>
