
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';
import withIcons from './withIcons.vue';
import states from './states.vue';
import sizes from './sizes.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<div class="font-weight-light">Taking action, making choices and confirming decisions.</div>
<p>The component’s API such as <code class="">props</code>, <code class="">events</code> and <code class="">slots</code> can be found on
the <a href="https://vuetifyjs.com/en/api/v-btn/">API document of <code class="">v-btn</code></a>.</p>
<h2>Basic usage</h2>
<div class="component-example">
    <basic-usage></basic-usage>
            <load-vue-file component="AdsBtn" demo-file="basicUsage" type="virtual"></load-vue-file>
</div>
<h2>With icons</h2>
<div class="component-example">
    <with-icons></with-icons>
            <load-vue-file component="AdsBtn" demo-file="withIcons" type="virtual"></load-vue-file>
</div>
<h2>Sizes</h2>
<div class="component-example">
    <sizes></sizes>
            <load-vue-file component="AdsBtn" demo-file="sizes" type="virtual"></load-vue-file>
</div>
<h2>States</h2>
<div class="component-example">
    <states></states>
            <load-vue-file component="AdsBtn" demo-file="states" type="virtual"></load-vue-file>
</div>
</div>
</template>
