<script setup lang="ts">
import { ref } from "vue";

const state = ref({
  items: ["foo", "bar", "fizz", "buzz"],
  value: "foo",
});
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="state.value"
          :items="state.items"
          label="Default"
        />
      </v-col>
      <v-col cols="12">
        <v-combobox
          v-model="state.value"
          :items="state.items"
          density="comfortable"
          label="Comfortable"
        />
      </v-col>
      <v-col cols="12">
        <v-combobox
          v-model="state.value"
          :items="state.items"
          density="compact"
          label="Compact"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
