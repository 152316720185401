
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>The UI component library, <a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/doe-ui-core-v3/"><code class="">doe-ui-core-v3</code></a>, is built upon Vuetify 3 but does not have Vuetify 3 as a dependency; rather, it is a peer-dependency.
This remains the same as ADS 1, and the template project <a href="https://bitbucket.org/nsw-education/doe-ui-template-v3"><code class="">doe-ui-template-v3</code></a> has both <code class="">vuetify 3</code> and <code class="">doe-ui-core-v3</code> installed, allowing users to use both Vuetify 3 and ADS 3 components in their projects.</p>
<p>ADS 3 has two types of components:</p>
<div class="pl-5">
<ul>
<li>Enhanced Vuetify components: as mentioned above, they are enhanced mainly by using <code class="">defaults</code>, SASS variables and some CSS overrides.</li>
<li>Custom-built components: all coded in TypeScript using the Composition API.</li>
</ul>
</div>
<p>Additionally, in v3, components can be imported separately in the template project (similar to ADS 1), or used as global components (similar to Vuetify).</p>
<p>All UI components and its configurations, including defaults, themes, icons, styling overrides, etc. are encapsulated in <code class="">doe-ui-core-v3</code>. The configurations are then imported and used in <code class="">doe-ui-template-v3</code>.</p>
<p>Configurations and ADS components are decoupled from the template project, <code class="">doe-ui-template-v3</code>. This separation of concerns ensures easier long-term maintenance.</p>
</div>
</template>
