<script lang="ts"></script>

<template>
  <v-layout style="overflow: visible">
    <v-app-bar>
      <template #prepend>
        <v-app-bar-nav-icon />
        <ads-app-icon app-name="my-nsw-gov" transparent />
      </template>

      <v-app-bar-title class="ml-2 text-white">
        <h3>App name</h3>
      </v-app-bar-title>

      <v-spacer />

      <v-btn icon>
        <v-icon>$helpCircleOutline</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>$bellBadgeOutline</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>$cogOutline</v-icon>
      </v-btn>
    </v-app-bar>
  </v-layout>
</template>

<style lang="scss" scoped></style>
