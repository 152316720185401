
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>The template project <a href="https://bitbucket.org/nsw-education/doe-ui-template-v3"><code class="">doe-ui-template-v3</code></a> is the sole repository developers need to download to initiate their ADS 3 application development. It comes equipped with:</p>
<div class="pl-5">
<ul>
<li><code class="">vue 3</code>,</li>
<li><code class="">typeScript</code>,</li>
<li><code class="">vuetify 3</code>,</li>
<li><code class="">doe-ui-core-v3</code>,</li>
<li><code class="">Pinia</code>,</li>
<li><code class="">vue-router</code></li>
<li><code class="">VueUse</code>,</li>
<li><code class="">Axios</code></li>
</ul>
</div>
<p>and many other things configured for rapid application development.</p>
<div class="mt-10">
<h1>Pre-configuration</h1>
</div>
<p>The pre-configuration includes:</p>
<div class="pl-5">
<ul>
<li>Settings for MyEssentials icons in <code class="">index.html</code></li>
<li>ADS configurations for Vuetify in <code class="">/plugins/vuetify.ts</code></li>
<li><code class="">public/favicon.ico</code> is replaced with the default NSW Government favicon</li>
<li><code class="">.editorconfig</code> file is added</li>
<li><code class="">.gitignore</code> file is added</li>
<li><code class="">.eslintrc.js</code> and <code class="">.prettierrc.js</code> files configured with rules for Vue 3, Vuetify 3 and TypeScript</li>
</ul>
</div>
<p>Once the template is opened in your IDE, you can freely choose a package manager for your project, whether it’s <code class="">npm</code>, <code class="">pnpm</code>, <code class="">yarn</code>, or <code class="">bun</code>, and proceed to install the dependencies and launch the template project locally:</p>
<pre v-pre="true"><code class="language-shell">npm install
</code></pre>
<pre v-pre="true"><code class="language-shell">npm run dev
</code></pre>
<div class="mt-10">
<h1>Create an individual Git repository for your project</h1>
</div>
<p>The <code class="">doe-ui-template-v3</code> repository intentionally excludes <code class="">*.lock</code> files to allow users to install the latest dependencies when initially downloading the template and starting their project development. After downloading the template, users should create their individual Git repositories for their projects. In their projects, they can choose to include their own <code class="">*.lock</code> files by editing the <code class="">.gitignore</code> file.</p>
<div class="mt-10">
<h1>Customizing the template project</h1>
</div>
<p>The template project is ready to be used out-of-the-box for projects, and please feel free to add or remove any dependencies as per your needs. For example, if you don’t want to use <code class="">pinia</code> for your state management, simply uninstall it, e.g.</p>
<pre v-pre="true"><code class="language-shell">npm uninstall Pinia
</code></pre>
<p>and then remove the import and reference in the project.</p>
<h1>Examples for other project settings</h1>
<p>Eslint and Prettier are configured in the template, and we aim to avoid incorporating excessive pre-configurations since each developer or project may have unique preferences. Therefore, we’ve provided additional configurations for your reference. Feel free to utilize them in your projects as needed.</p>
<div class="mt-10">
<h1>IDE recommendation</h1>
</div>
<p>We recommend using VSCode or WebStorm. Please refer to the Vue 3 documentation for <a href="https://vuejs.org/guide/scaling-up/tooling.html#ide-support">IDE support</a>.</p>
</div>
</template>
