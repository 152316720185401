
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';
import alternativeExample from './alternativeExample.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<p><code class="">v-app-bar</code> examples can be seen on the <a href="https://vuetifyjs.com/en/components/app-bars/">official Vuetify documentation</a>. The component’s API such as <code class="">props</code>, <code class="">events</code> and <code class="">slots</code> can be found on the <a href="https://vuetifyjs.com/en/api/v-select/">API document</a> of <a href="https://vuetifyjs.com/en/api/v-app-bar/"><code class="">v-app-bar</code></a>.</p>
<p>The examples below shows how to use <code class="">v-app-bar</code> with other components.</p>
<div class="mt-3">
<h2>Basic usage</h2>
</div>
<div class="component-example">
    <basic-usage class="mb-16 pb-4"></basic-usage>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsAppBar" demo-file="basicUsage" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-3">
<h2>Alternative example</h2>
</div>
<div class="component-example">
    <alternative-example class="mb-16 pb-4"></alternative-example>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsAppBar" demo-file="alternativeExample" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
</div>
</template>
