<script setup lang="ts"></script>

<template>
  <v-container>
    <!--    1. disabled-->
    <v-row>
      <v-col cols="6">
        <v-file-input label="Disabled" disabled />
      </v-col>
    </v-row>

    <!--    2. error-->
    <v-row>
      <v-col cols="6">
        <v-file-input label="Error" error />
      </v-col>
    </v-row>
  </v-container>
</template>
