
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>To contribute to ADS 3, follow these steps:</p>
<div class="pl-5">
<ol>
<li>Check out the monorepo <a href="https://bitbucket.org/nsw-education/ads-v3-mono"><code class="">ads-v3-mono</code></a>, and make sure you have <code class="">pnpm</code> installed on your machine:</li>
</ol>
<pre v-pre="true"><code class="language-shell">npm install pnpm -g
</code></pre>
<ol start="2">
<li>In the project root, run:</li>
</ol>
<pre v-pre="true"><code class="language-shell">pnpm i
</code></pre>
<p>This will install the dependencies for the “workspace”.</p>
<ol start="3">
<li>Then run:</li>
</ol>
<pre v-pre="true"><code class="language-shell">pnpm start
</code></pre>
<p>This command starts up the ADS 3 doc site, and you can test or add components under the <code class="">doe-ui-core-v3</code> package. Please refer to the <code class="">package.json</code> file in the project root for more available commands. To render your new component on the doc site, add the new component’s path in its Vue Router configuration (please follow existing components as an example).</p>
</div>
<div class="mt-10">
<h1>Raise a pull request</h1>
</div>
<div class="pl-5">
<ol>
<li>Create a new branch with a meaningful name, for example:
<ul>
<li><code class="">feature/ADS-001/support-dark-theme</code></li>
<li><code class="">bugfix/ADS-002/fix-tabs-hover-colour</code></li>
<li><code class="">hotfix/ADS-003/fix-register-button-not-working</code></li>
</ul>
</li>
<li>Make the necessary changes and/or additions.</li>
<li>Create a pull request. Ensure that your PR provides details about the changes made.</li>
<li>The ADS team will review your pull request. If everything is in order, the PR will be merged and scheduled to be released in the next version.</li>
</ol>
</div>
<div class="mt-10">
<h1>Report bugs or raise requests</h1>
</div>
<p>You can report a bug or raise a request by creating a Jira ticket on the <a href="https://jira.education.nsw.gov.au/secure/RapidBoard.jspa?rapidView=2499&amp;projectKey=ADS">ADS Kanban board</a>. Please provide sufficient details, including steps to reproduce the issue or a clear description of your request. This will help the ADS team address your concern effectively.</p>
<div class="mt-16">
<h1>Resources for developers</h1>
</div>
<div class="pl-5">
<ul>
<li><a href="https://vuejs.org/">Vue.js 3</a></li>
<li><a href="https://vuetifyjs.com/en/">Vuetify 3</a></li>
<li><a href="https://www.typescriptlang.org/">TypeScript</a></li>
<li><a href="https://vueuse.org/">VueUse</a></li>
<li><a href="https://pnpm.io/">pnpm</a></li>
</ul>
</div>
</div>
</template>
