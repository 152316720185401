<script setup lang="ts"></script>

<template>
  <div class="d-flex flex-column ga-6">
    <v-alert
      type="info"
      title="Info alert summary goes here"
      text="Optional details here what happens when there is more text."
    />
    <v-alert
      type="warning"
      title="Warning alert summary goes here"
      text="Optional details here what happens when there is more text."
    />
    <v-alert
      type="error"
      title="Error alert summary goes here"
      text="Optional details here what happens when there is more text."
    />
    <v-alert
      type="success"
      title="Success alert summary goes here"
      text="Optional details here what happens when there is more text."
    />
  </div>
</template>
