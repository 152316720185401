<script setup lang="ts">
import { ref } from "vue";

const items = ref(["Foo", "Bar", "Fizz", "Buzz"]);
</script>

<template>
  <v-container>
    <v-row>
      <!--    1. disabled-->
      <v-col>
        <v-select :items="items" label="Disabled" disabled />
      </v-col>

      <!--    2. error-->
      <v-col>
        <v-select :items="items" label="Error" error />
      </v-col>

      <!--    3. readonly-->
      <v-col>
        <v-select :items="items" label="Readonly" readonly />
      </v-col>
    </v-row>
  </v-container>
</template>
