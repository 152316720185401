
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<div class="font-weight-light">Allows the user to switch between DoE apps.</div>
<div class="mt-3">
<h2>Basic usage</h2>
</div>
<div class="component-example">
    <basic-usage></basic-usage>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsEnvironmentInfo" demo-file="basicUsage"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-10 mb-3">
<h2>Props</h2>
</div>
<p><highlightjs language="javascript" code="export const adsEnvironmentInfoProps = {
versionNo: {
  type: String,
  default: &#x27;&#x27;
},
isVisible: {
  type: Boolean,
  default: false
},
environment: {
  type: String,
  default: &#x27;&#x27;
}} as const;"></highlightjs></p>
</div>
</template>
