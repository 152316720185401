import { createVuetify } from "vuetify";
import "@nswdoe/doe-ui-core-v3/componentstyles";
import "vuetify/styles";
import { ads } from "@nswdoe/doe-ui-core-v3";
import {
  mdiApplicationBracketsOutline,
  mdiCodeJson,
  mdiFileDocumentOutline,
  mdiFlaskOutline,
  mdiFormatText,
  mdiHomeOutline,
  mdiMoonWaxingCrescent,
  mdiPaletteOutline,
  mdiPuzzleOutline,
  mdiSquareRounded,
  mdiWhiteBalanceSunny,
  mdiHelpCircleOutline,
  mdiBellBadgeOutline,
  mdiCogOutline,
  mdiClose,
  mdiImageOutline,
} from "@mdi/js";

export default createVuetify({
  // @ts-expect-error
  blueprint: ads,
  icons: {
    aliases: {
      puzzleOutline: mdiPuzzleOutline,
      homeOutline: mdiHomeOutline,
      paletteOutline: mdiPaletteOutline,
      formatText: mdiFormatText,
      codeJson: mdiCodeJson,
      applicationBracketsOutline: mdiApplicationBracketsOutline,
      fileDocumentOutline: mdiFileDocumentOutline,
      squareRounded: mdiSquareRounded,
      whiteBalanceSunny: mdiWhiteBalanceSunny,
      moonWaxingCrescent: mdiMoonWaxingCrescent,
      flaskOutline: mdiFlaskOutline,
      helpCircleOutline: mdiHelpCircleOutline,
      bellBadgeOutline: mdiBellBadgeOutline,
      cogOutline: mdiCogOutline,
      close: mdiClose,
      imageOutline: mdiImageOutline,
    },
  },
});
