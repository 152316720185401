<script setup lang="ts">
import { ref } from "vue";
const alert = ref(true);
</script>

<template>
  <v-alert
    v-model="alert"
    type="warning"
    title="Uh oh, something isn't right"
    text="Something happened that wasn't supposed to happen, or the other way around. Dismiss this alert at your peril."
    closable
  />
  <div v-if="!alert" class="text-center">
    <v-btn prepend-icon="$loading" @click="alert = true"> Reset </v-btn>
  </div>
</template>
