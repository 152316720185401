<template>
  <v-container>
    <v-responsive>
      <v-row>
        <v-col>
          <h3>Colour vs Heriarchy</h3>
        </v-col>
        <v-col>
          <h3>Primary<br /><code>variant="flat"</code></h3>
        </v-col>
        <v-col>
          <h3>Secondary<br /><code>variant="outlined"</code></h3>
        </v-col>
        <v-col>
          <h3>Tertiary<br /><code>variant="text"</code></h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>
            <v-icon color="primary" icon="$squareRounded" />
            <span>Navy/10<br /><code>color="primary"</code></span>
          </h3>
        </v-col>
        <v-col>
          <v-btn color="primary"> Submit application </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" variant="outlined"> Previous record </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" variant="text"> Hide alert </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>
            <v-icon color="secondary" icon="$squareRounded" />
            <span>Teal/10<br /><code>color="secondary"</code></span>
          </h3>
        </v-col>
        <v-col class="bg-primary">
          <v-btn color="secondary"> Submit application </v-btn>
        </v-col>
        <v-col class="bg-primary">
          <v-btn color="secondary" variant="outlined"> Previous record </v-btn>
        </v-col>
        <v-col class="bg-primary">
          <v-btn color="secondary" variant="text"> Hide alert </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>
            <v-icon color="error" icon="$squareRounded" />
            <span>Red/10<br /><code>color="error"</code></span>
          </h3>
        </v-col>
        <v-col>
          <v-btn color="error"> Delete enrolment </v-btn>
        </v-col>
        <v-col>
          <v-btn color="error" variant="outlined"> Undo last action </v-btn>
        </v-col>
        <v-col>
          <v-btn color="error" variant="text"> Cancel meeting </v-btn>
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>
