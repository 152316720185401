<script setup lang="ts">
const items = [
  { text: "Real-Time", icon: "$homeOutline" },
  { text: "Audience", icon: "$whiteBalanceSunny" },
  { text: "Conversions", icon: "$flaskOutline" },
];
</script>

<template>
  <v-container>
    <v-card max-width="500">
      <v-row>
        <v-col>
          <v-list>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              :value="item"
              color="primary"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>

              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
