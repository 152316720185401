<script setup lang="ts">
import { ref } from "vue";

const indeterminate = ref(true);
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="4"> on </v-col>
      <v-col cols="4"> off </v-col>
      <v-col cols="4"> indeterminate </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-checkbox :model-value="true" />
      </v-col>
      <v-col cols="4">
        <v-checkbox :model-value="false" />
      </v-col>
      <v-col cols="4">
        <v-checkbox
          :indeterminate="indeterminate"
          :class="indeterminate ? 'indeterminate-color' : ''"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"> on disabled </v-col>
      <v-col cols="4"> off disabled </v-col>
      <v-col cols="4"> indeterminate disabled </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-checkbox :model-value="true" disabled />
      </v-col>
      <v-col cols="4">
        <v-checkbox :model-value="false" disabled />
      </v-col>
      <v-col cols="4">
        <v-checkbox disabled indeterminate />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
/**
  Vuetify does not use the primary color for the indeterminate state, which is unlike other MD UI libraries.
  Here we can define a class to dynamically apply the primary color onto it.
 */
.indeterminate-color {
  color: rgb(var(--v-theme-primary));
}
</style>
