
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><h2>1.1.0-beta.2 - 18 Sep 2024</h2>
<ul>
<li>Update CSS custom variables</li>
<li>Update defaults and styling for VTabs</li>
</ul>
<h2>1.1.0-beta.1 - 26 Jul 2024</h2>
<ul>
<li>Update defaults and styling for VSwitch, VTabs, VDatePicker</li>
<li>Update vue to latest version</li>
<li>Update export types</li>
<li>Update anchor colour</li>
</ul>
<h2>1.0.1 - 08 Jul 2024</h2>
<ul>
<li>Defaulting vField’s density to “comfortable”</li>
</ul>
<h2>1.0.0 - 29 May 2024</h2>
<ul>
<li>ADS 3 production release v1.0.0</li>
</ul>
<h2>1.0.0-beta.14 - 28 May 2024</h2>
<ul>
<li>Add VTabs defaults</li>
<li>Update Header component</li>
<li>Fix TS compilation and bundling</li>
<li>Configure new border-radius defaults</li>
<li>Update Vuetify to the latest v3.6.7</li>
<li>Update default VChip style to tonal</li>
<li>Apply the new NSW design system color palette</li>
</ul>
<h2>1.0.0-beta.13 - 15 May 2024</h2>
<ul>
<li>Update colors for ADS themes</li>
<li>Update defaults for VList, VListItem, VCheckbox, VRadio, VDataTable, VFileInput</li>
<li>Update css overrides for VAlert, VLabel</li>
<li>Separate blueprint options into its own modules</li>
<li>Update AdsHeader</li>
</ul>
<h2>1.0.0-beta.12 - 08 Mar 2024</h2>
<ul>
<li>BREAKING change: Move most Vuetify options into blueprint</li>
<li>Update styling for form inputs</li>
</ul>
<h2>1.0.0-beta.11 - 01 Mar 2024</h2>
<ul>
<li>Update error color to meet contrast ratio</li>
<li>Update TextField for design update</li>
</ul>
<h2>1.0.0-beta.10 - 29 Feb 2024</h2>
<ul>
<li>Update styling and theme as per design meetings</li>
<li>Change ADS 3 from ‘aliasing first’ to ‘configuration first’</li>
</ul>
<h2>1.0.0-beta.9 - 27 Feb 2024</h2>
<ul>
<li>Add ADS icon subset</li>
</ul>
<h2>1.0.0-beta.8 - 31 Jan 2024</h2>
<ul>
<li>Update scss for AdsBtn</li>
<li>Rename AdsBanner to AdsHeader to avoid conflicts with Vuetify banner’s alias</li>
<li>Improve scss inherit strategy</li>
<li>Fix meta protected route feature for Oauth plugin</li>
</ul>
<h2>1.0.0-beta.7 - 31 Jan 2024</h2>
<ul>
<li>Added compilation feature for the package</li>
<li>Updated TS support for Oauth plugin</li>
<li>Removed bundled icons and provided facility for developers to extend the icon subset using @mdi/js</li>
</ul>
<h2>1.0.0-beta.6 - 25 Jan 2024</h2>
<ul>
<li>Fixed import statement for Oauth plugin</li>
</ul>
<h2>1.0.0-beta.5 - 25 Jan 2024</h2>
<ul>
<li>Updated Oauth plugin to support multiple SSO Oauth</li>
</ul>
<h2>1.0.0-beta.4 - 17 Jan 2024</h2>
<ul>
<li>Updated Oauth plugin to resolve the ‘buffer’ issue</li>
</ul>
<h2>1.0.0-beta.3 - 12 Jan 2024</h2>
<ul>
<li>Released version 1.0.0-beta.3</li>
<li>Updated Oauth plugin to be used with composition API</li>
<li>Minor fixes</li>
</ul>
<h2>1.0.0-beta.2 - 01 Dec 2023</h2>
<ul>
<li>Released version 1.0.0-beta.2</li>
</ul>
<h2>1.0.0-beta.1 - 21 Nov 2023</h2>
<ul>
<li>Released version 1.0.0-beta.1</li>
</ul>
</div>
</template>
