import AboutADS from "@/views/AboutADS/index.vue";
import ColorPalette from "@/views/ColorPalette/index.vue";
import Typography from "@/views/Typography/index.vue";
import Icons from "@/views/Icons/index.vue";
import AdsOverview from "@/views/TechnicalSpecification/AdsOverview/index.vue";
import ComponentLibrary from "@/views/TechnicalSpecification/ComponentLibrary/index.vue";
import TemplateProject from "@/views/TechnicalSpecification/TemplateProject/index.vue";
import ApplicationLayout from "@/views/TechnicalSpecification/ApplicationLayout/index.vue";
import TypeScriptGuide from "@/views/TechnicalSpecification/TypeScriptGuide/index.vue";
import HowToContribute from "@/views/TechnicalSpecification/HowToContribute/index.vue";
import HowToStart from "@/views/HowToStart/index.vue";
import ChangeLogs from "@/views/ChangeLogs/index.vue";

import AdsBtn from "@/aliases/AdsBtn/docs/AdsBtn.md";
import AdsFileInput from "@/aliases/AdsFileInput/docs/AdsFileInput.md";
import AdsSelect from "@/aliases/AdsSelect/docs/AdsSelect.md";
import AdsTextarea from "@/aliases/AdsTextarea/docs/AdsTextarea.md";
import AdsTextField from "@/aliases/AdsTextField/docs/AdsTextField.md";
import AdsCheckbox from "@/aliases/AdsCheckbox/docs/AdsCheckbox.md";
import AdsCombobox from "@/aliases/AdsCombobox/docs/AdsCombobox.md";
import AdsAutocomplete from "@/aliases/AdsAutocomplete/docs/AdsAutocomplete.md";
import AdsRadio from "@/aliases/AdsRadio/docs/AdsRadio.md";
import AdsSwitch from "@/aliases/AdsSwitch/docs/AdsSwitch.md";
import AdsSlider from "@/aliases/AdsSlider/docs/AdsSlider.md";
import AdsRangeSlider from "@/aliases/AdsRangeSlider/docs/AdsRangeSlider.md";
import AdsAppBar from "@/aliases/AdsAppBar/docs/AdsAppBar.md";
import AdsDatePicker from "@/aliases/AdsDatePicker/docs/AdsDatePicker.md";
import AdsAlert from "@/aliases/AdsAlert/docs/AdsAlert.md";
import AdsDataTable from "@/aliases/AdsDataTable/docs/AdsDataTable.md";
import AdsNavigationDrawer from "@/aliases/AdsNavigationDrawer/docs/AdsNavigationDrawer.md";
import AdsTabs from "@/aliases/AdsTabs/docs/AdsTabs.md";
import AdsMenu from "@/aliases/AdsList/docs/AdsList.md";
import AdsDialog from "@/aliases/AdsDialog/docs/AdsDialog.md";
import AdsChip from "@/aliases/AdsChip/docs/AdsChip.md";
import AdsInitialsIcon from "@/components/AdsInitialsIcon/docs/AdsInitialsIcon.md";
import AdsHeader from "@/components/AdsHeader/docs/AdsHeader.md";
import AdsAppIcon from "@/components/AdsAppIcon/docs/AdsAppIcon.md";
import AdsEnvironmentInfo from "@/components/AdsEnvironmentInfo/docs/AdsEnvironmentInfo.md";

export const routes = [
  {
    path: "/",
    name: "About ADS 3",
    component: AboutADS,
    meta: {
      subtitle: "Start to build your first ADS 3 application",
    },
  },
  {
    path: "/colors",
    name: "Color palette",
    component: ColorPalette,
  },
  {
    path: "/typography",
    name: "Typography",
    component: Typography,
  },
  {
    path: "/icons",
    name: "Icons",
    component: Icons,
  },
  {
    path: "/specification/overview",
    name: "ADS 3 Overview",
    component: AdsOverview,
  },
  {
    path: "/specification/library",
    name: "Component Library",
    component: ComponentLibrary,
  },
  {
    path: "/specification/template",
    name: "Template Project",
    component: TemplateProject,
  },
  {
    path: "/specification/layout",
    name: "Application Layout",
    component: ApplicationLayout,
  },
  {
    path: "/specification/typescript",
    name: "Typescript Guide",
    component: TypeScriptGuide,
  },
  {
    path: "/specification/contribute",
    name: "How To Contribute",
    component: HowToContribute,
  },
  {
    path: "/start",
    name: "How to start",
    component: HowToStart,
    meta: {
      subtitle: "Start to build your first ADS 3 application",
    },
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: ChangeLogs,
  },
  {
    path: "/components/AdsBtn",
    name: "Button",
    component: AdsBtn,
  },
  {
    path: "/components/AdsFileInput",
    name: "File input",
    component: AdsFileInput,
  },
  {
    path: "/components/AdsSelect",
    name: "Select",
    component: AdsSelect,
  },
  {
    path: "/components/AdsTextarea",
    name: "Textarea",
    component: AdsTextarea,
  },
  {
    path: "/components/AdsTextField",
    name: "Text field",
    component: AdsTextField,
  },
  {
    path: "/components/AdsCheckbox",
    name: "Checkbox",
    component: AdsCheckbox,
  },
  {
    path: "/components/AdsCombobox",
    name: "Combobox",
    component: AdsCombobox,
  },
  {
    path: "/components/AdsAutocomplete",
    name: "Autocomplete",
    component: AdsAutocomplete,
  },
  {
    path: "/components/AdsRadio",
    name: "Radio button",
    component: AdsRadio,
  },
  {
    path: "/components/AdsSwitch",
    name: "Switch",
    component: AdsSwitch,
  },
  {
    path: "/components/AdsSlider",
    name: "Slider",
    component: AdsSlider,
  },
  {
    path: "/components/AdsRangeSlider",
    name: "Range slider",
    component: AdsRangeSlider,
  },
  {
    path: "/components/AdsAppBar",
    name: "App bar",
    component: AdsAppBar,
  },
  {
    path: "/components/AdsNavigationDrawer",
    name: "Navigation drawer",
    component: AdsNavigationDrawer,
  },
  {
    path: "/components/AdsInitialsIcon",
    name: "Initials icon",
    component: AdsInitialsIcon,
  },
  {
    path: "/components/AdsHeader",
    name: "Header",
    component: AdsHeader,
  },
  {
    path: "/components/AdsAppIcon",
    name: "App icon",
    component: AdsAppIcon,
  },
  {
    path: "/components/AdsDialog",
    name: "Dialog",
    component: AdsDialog,
  },
  {
    path: "/components/AdsEnvironmentInfo",
    name: "Environment info",
    component: AdsEnvironmentInfo,
  },
  {
    path: "/components/AdsTabs",
    name: "Tabs",
    component: AdsTabs,
  },
  {
    path: "/components/AdsList",
    name: "List",
    component: AdsMenu,
  },
  {
    path: "/components/AdsChip",
    name: "Chip",
    component: AdsChip,
  },
  {
    path: "/components/AdsDatePicker",
    name: "Date picker",
    component: AdsDatePicker,
  },
  {
    path: "/components/AdsDataTable",
    name: "Data table",
    component: AdsDataTable,
  },
  {
    path: "/components/AdsAlert",
    name: "Alert",
    component: AdsAlert,
  },
];
