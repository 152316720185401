<script setup lang="ts">
import { ref } from "vue";

const tab = ref(null);
const state = ref({
  items: [
    {
      tab: "Favourites",
      content:
        "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
      icon: "favorite",
    },
    {
      tab: "Contacts",
      content: "<p>Nobis aliter videtur, recte secusne, postea.</p>",
      icon: "phone",
    },
    {
      tab: "Users",
      content: "<p>Quae sequuntur igitur? Ita nemo beato beatior.</p>",
      icon: "account_box",
    },
    {
      tab: "History",
      content: "<p>Nosti, credo, illud: Nemo pius est, qui pietatem.</p>",
      icon: "history",
    },
    {
      tab: "File",
      content: "<p>Multoque hoc melius nos veriusque quam Stoici.</p>",
      icon: "description",
    },
    {
      tab: "Search",
      content:
        "<p>Sin laboramus, quis est, qui alienae modum statuat industriae?</p>",
      icon: "search",
    },
    {
      tab: "Bluetooth",
      content:
        "<p>Nam et complectitur verbis, quod vult, et dicit plane, quod intellegam.</p>",
      icon: "bluetooth",
    },
    {
      tab: "Alarm",
      content:
        "<p>Hosne igitur laudas et hanc eorum, inquam, sententiam sequinos censes oportere?</p>",
      icon: "alarm",
    },
    {
      tab: "Calendar",
      content:
        "<p>Quid ergo attinet gloriose loqui, nisi constanter loquare?</p>",
      icon: "today",
    },
    {
      tab: "Feedback",
      content: "<p>Nam quid possumus facere melius?</p>",
      icon: "feedback",
    },
    {
      tab: "Delete",
      content: "<p>Ut aliquid scire se gaudeant?</p>",
      icon: "delete",
    },
    {
      tab: "Home",
      content: "<p>Qui autem esse poteris, nisi te amor ipse ceperit?</p>",
      icon: "home",
    },
  ],
});
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-tabs v-model="tab">
            <v-tab value="one">Item One</v-tab>
            <v-tab value="two">Item Two</v-tab>
            <v-tab value="three">Item Three</v-tab>
          </v-tabs>

          <v-card-text>
            <v-tabs-window v-model="tab">
              <v-tabs-window-item value="one"> One </v-tabs-window-item>

              <v-tabs-window-item value="two"> Two </v-tabs-window-item>

              <v-tabs-window-item value="three"> Three </v-tabs-window-item>
            </v-tabs-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
