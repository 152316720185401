<script setup lang="ts">
import { ref } from "vue";
import { headerData } from "./constants";

const state = ref(headerData);
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <ads-header
          :title="state.title"
          :subtitle="state.subtitle"
          icon="$imageOutline"
          :icon-size="state.iconSize"
          :background-image-url="state.backgroundImages[0].value"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ads-header
          :title="state.title"
          :subtitle="state.subtitle"
          icon="$imageOutline"
          :icon-size="state.iconSize"
          :no-background="true"
        />
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col>
        <ads-header
          theme="dark"
          :title="state.title"
          :subtitle="state.subtitle"
          icon="$imageOutline"
          :icon-size="state.iconSize"
          :background-image-url="state.backgroundImages[1].value"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
