
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';
import basicStates from './basicStates.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<p><code class="">v-file-input</code> examples can be seen on the <a href="https://vuetifyjs.com/en/components/file-inputs/">official Vuetify documentation</a>. The component’s API such as <code class="">props</code>, <code class="">events</code> and <code class="">slots</code> can be found on the <a href="https://vuetifyjs.com/en/api/v-file-input/">API document</a> of <a href="https://vuetifyjs.com/en/api/v-file-input/"><code class="">v-file-input</code></a>.</p>
<p>The text field component is configured to have the following defaults:</p>
<ul>
<li><code class="">outlined</code> variant</li>
<li><code class="">colour</code> is set to <code class="">primary</code>. The colour of the label, and any appended or prepended icons</li>
<li><code class="">prependIcon</code> is set to be empty</li>
<li><code class="">prependInnerIcon</code> is set to be ‘mdiPaperclip’</li>
</ul>
<p>The examples below outline the default configuration and how it differs from Vuetify’s <code class="">v-file-input</code> component.</p>
<div class="mt-3">
<h2>Basic usage</h2>
</div>
<div class="component-example">
    <basic-usage></basic-usage>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsFileInput" demo-file="basicUsage" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-10">
<h2>States</h2>
</div>
<div class="component-example">
    <basic-states></basic-states>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsFileInput" demo-file="basicStates" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
</div>
</template>
