<script setup lang="ts">
import { onMounted, ref } from "vue";

const props = defineProps({
  component: {
    type: String,
    default: "",
    require: true,
  },
  demoFile: {
    type: String,
    default: "",
    require: true,
  },
  type: {
    type: String,
    default: "",
  },
});

const fileStr = ref("");
const showCode = ref(false);
const borderStyle = "1px solid rgba(0,0,0,.06)";
const border = ref(borderStyle);

onMounted(() => {
  fetchVueFile();
});

const fetchVueFile = async () => {
  if (props.type) {
    fileStr.value = (
      await import(
        `../aliases/${props.component}/docs/${props.demoFile}.vue?raw`
      )
    ).default;
  } else {
    fileStr.value = (
      await import(
        `../components/${props.component}/docs/${props.demoFile}.vue?raw`
      )
    ).default;
  }
};

const showHideCode = () => {
  showCode.value = !showCode.value;
  border.value = showCode.value ? "0" : borderStyle;
};
</script>

<template>
  <div class="pre-code-box">
    <transition name="slide-fade">
      <pre v-if="showCode" v-highlight>
        <code>{{ fileStr }}</code>
      </pre>
    </transition>
    <v-btn block variant="outlined" color="primary" @click="showHideCode">
      <span>{{ showCode ? "Hide code" : "Show code" }}</span>
    </v-btn>
  </div>
</template>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0.5;
}

.pre-code-box {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-top: 0;
  margin: 0px 0 15px 0;
  position: relative;
  transition: all 0.15s ease-out;

  .show-code {
    width: 100%;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    box-shadow: 0px 16px 15px -16px rgb(0 0 0 / 10%);
    cursor: pointer;

    &:hover {
      background: #f9f9f9;
      opacity: var(--v-medium-emphasis-opacity);
      font-weight: bold;
    }
  }

  &:hover {
    box-shadow: 0px 16px 15px -16px rgb(0 0 0 / 10%);
  }
}
</style>
