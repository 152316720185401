<script setup lang="ts"></script>

<template>
  <v-container>
    <v-responsive>
      <v-row>
        <v-col>
          <h3>Primary</h3>
        </v-col>
        <v-col>
          <v-btn color="primary"> Default </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" active> Active </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" disabled> Disabled </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>Secondary</h3>
        </v-col>
        <v-col>
          <v-btn color="primary" variant="outlined"> Default </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" variant="outlined" active> Active </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" variant="outlined" disabled> Disabled </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>Tertiary</h3>
        </v-col>
        <v-col>
          <v-btn color="primary" variant="text"> Default </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" variant="text" active> Active </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" variant="text" disabled> Disabled </v-btn>
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>
