import { ExtractPropTypes, PropType } from "vue";

type CallbackFunction = () => void;

export interface NavigationItem {
  id?: string;
  title?: string;
  icon?: string;
  href?: string;
  route?: string;
  target?: string;
  exact?: boolean;
  clickHandler?: CallbackFunction;
  items?: NavigationItem[];
}

export const exampleNavigationDrawerProps = {
  color: {
    type: String,
    default: "white", // override the default value 'undefined' in v-navigation-drawer
  },
  width: {
    type: [String, Number] as PropType<string | number>,
    default: 300, // override the default value 256 in v-navigation-drawer
  },
  railWidth: {
    type: [Number, String] as PropType<number | string>,
    default: 70, // override the default value 56 in v-navigation-drawer
  },
  items: {
    type: Array as PropType<NavigationItem[]>,
    default: () => [],
  },
} as const;

export type ExampleNavigationDrawerProps = Partial<
  ExtractPropTypes<typeof exampleNavigationDrawerProps>
>;
