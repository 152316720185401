<script setup lang="ts">
function required(v: string) {
  return !!v || "Field is required";
}
</script>

<template>
  <v-container>
    <!--    1. disabled-->
    <v-row>
      <v-col>
        <v-text-field label="Disabled" placeholder="Placeholder" disabled />
      </v-col>
      <v-col>
        <v-text-field
          label="Disabled with icons"
          placeholder="Placeholder"
          prepend-inner-icon="$magnify"
          append-inner-icon="$moonWaxingCrescent"
          disabled
        />
      </v-col>
    </v-row>

    <!--    2. error-->
    <v-row>
      <v-col>
        <v-text-field
          label="Required field on blur"
          placeholder="Placeholder"
          :rules="[required]"
        />
      </v-col>
      <v-col>
        <v-text-field
          label="Error state with icons"
          placeholder="Placeholder"
          prepend-inner-icon="$magnify"
          append-inner-icon="$moonWaxingCrescent"
          error
        />
      </v-col>
    </v-row>
  </v-container>
</template>
