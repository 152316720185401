<script setup lang="ts"></script>

<template>
  <v-container>
    <v-row>
      <v-col cols="4"> on </v-col>
      <v-col cols="4"> on disabled </v-col>
      <v-col cols="4"> off disabled </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-radio :model-value="true" />
      </v-col>
      <v-col cols="4">
        <v-radio :model-value="true" disabled />
      </v-col>
      <v-col cols="4">
        <v-radio :model-value="false" disabled />
      </v-col>
    </v-row>
  </v-container>
</template>
