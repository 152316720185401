
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';
import withSearch from './withSearch.vue';
import moreExamples from './moreExamples.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<p><code class="">v-data-table</code> examples can be seen on the <a href="https://vuetifyjs.com/en/components/data-tables/basics">official Vuetify documentation</a>. The component’s API such as <code class="">props</code>, <code class="">events</code> and <code class="">slots</code> can be found on the <a href="https://vuetifyjs.com/en/api/v-data-table/">API document</a> of <a href="https://vuetifyjs.com/en/api/v-data-table/"><code class="">v-data-table</code></a>.</p>
<p>The data table component is configured to have the following defaults:</p>
<ul>
<li><code class="">colour</code> is set to <code class="">primary</code>.</li>
<li><code class="">show-select</code>: is set to <code class="">true</code> to always show the select checkboxes.</li>
<li><code class="">items-per-page-text</code> is set to <code class="">Rows per page:</code> to meet the design.</li>
</ul>
<p>The examples below outline the default configuration and how it differs from Vuetify’s <code class="">v-data-table</code> component.</p>
<div class="mt-3">
<h2>Basic usage</h2>
</div>
<div class="component-example">
    <basic-usage></basic-usage>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsDataTable" demo-file="basicUsage" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-3">
<h2>With search</h2>
</div>
<div class="component-example">
    <with-search></with-search>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsDataTable" demo-file="withSearch" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-3">
<h2>More examples</h2>
</div>
<div class="component-example">
    <more-examples></more-examples>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsDataTable" demo-file="moreExamples" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
</div>
</template>
