<script setup lang="ts">
import { ref } from "vue";
const alert = ref(true);

const handleClick = () => window.alert("You did the thing!");

const types: ["error", "warning", "success", "info"] = [
  "error",
  "warning",
  "success",
  "info",
];
const currentType = ref(0);
const changeType = () => {
  if (currentType.value === types.length - 1) {
    currentType.value = 0;
  } else {
    currentType.value++;
  }
};
</script>

<template>
  <v-alert
    v-model="alert"
    :type="types[currentType]"
    title="You need to take action"
    closable
  >
    <template #text>
      <p>Looks like you need to do something important right now.</p>
      <p class="mt-6">
        <v-btn
          size="small"
          variant="outlined"
          class="ads-hyperlink-1"
          @click="handleClick"
        >
          Just do the thing
        </v-btn>
      </p>
    </template>
  </v-alert>
  <div class="d-flex ga-8 pa-4 justify-center items-center">
    <v-btn @click="changeType"> Change type </v-btn>
    <v-btn
      v-if="!alert"
      prepend-icon="$loading"
      @click="
        alert = true;
        currentType = 0;
      "
    >
      Reset
    </v-btn>
  </div>
</template>
