
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<p><code class="">v-list</code> examples can be seen on the <a href="https://vuetifyjs.com/en/components/lists/">official Vuetify documentation</a>. The component’s API such as <code class="">props</code>, <code class="">events</code> and <code class="">slots</code> can be found on the <a href="https://vuetifyjs.com/en/api/v-list/">API document</a> of <a href="https://vuetifyjs.com/en/api/v-list/"><code class="">v-menu</code></a>.</p>
<p><code class="">v-list</code> is often used together with <a href="https://vuetifyjs.com/en/api/v-list-item"><code class="">v-list-item</code></a>, and ADS 3 has the following defaults for <code class="">v-list</code> and <code class="">v-list-item</code>:</p>
<pre v-pre="true"><code class="language-typescript">  VList: {
    bgColor: &quot;background&quot;, // set the background color to be white, please see the `Colour palette` section for theme colors
    VListItem: {
      color: &quot;info&quot;        // set the color for active list items
    }
  }
</code></pre>
<div class="mt-3">
<h2>Basic usage</h2>
</div>
<div class="component-example">
    <basic-usage class="mb-16"></basic-usage>
    <v-container class="mt-16">
        <v-row>
          <v-col>
            <load-vue-file component="AdsList" demo-file="basicUsage" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-3">
<h2>Code Snippet</h2>
</div>
<p>Below is an example of using <code class="">v-list</code> and other components to create a classic user profile menu. Full code including definition of type, props and data can be seen here:</p>
<p><a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsMenu/docs/examples/ExampleProfile.vue">https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsMenu/docs/examples/ExampleProfile.vue</a></p>
<p><a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsMenu/docs/examples/ExampleProfile.ts">https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsMenu/docs/examples/ExampleProfile.ts</a></p>
<p><a href="https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsMenu/docs/examples/ExampleUsage.vue">https://bitbucket.org/nsw-education/ads-v3-mono/src/master/ads-doc-v3/src/aliases/AdsMenu/docs/examples/ExampleUsage.vue</a></p>
<div class="component-example">
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsList" demo-file="codeSnippet" type="virtual"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
</div>
</template>
