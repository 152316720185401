
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>All ADS custom-built components and code examples are coded using TypeScript and Composition API. Let’s take <code class="">AdsDialog</code> as an example:</p>
<p><img src="../images/7.png" alt=""></p>
<p>And its props are all defined in a dedicated TypeScript file named <code class="">ExampleDialog.ts</code>:</p>
<p><img src="../images/8.png" alt=""></p>
<p>When defining props for Vue components, it is important to make them readonly. To achieve this, we use <code class="">as const</code>, as seen on line 52.
Also, on line 37, for the <code class="">Array</code> type, we use the <code class="">PropType</code> utility type from Vue to explicitly inform TypeScript that this is an array of type <code class="">ActionButton</code> (which is an interface for the <code class="">ActionButton</code> object). Without using <code class="">PropType</code>, TypeScript cannot infer the type correctly.</p>
<p>On line 54, we employ another utility type, <code class="">ExtractPropTypes</code> from Vue, along with TypeScript’s built-in <code class="">Paitial</code>, to determine the type of all props and export them.</p>
<p>There is a <code class="">tsconfig.json</code> file in the template project root; feel free to update it to align with your preferences.</p>
<p><img src="../images/9.png" alt=""></p>
<p>Additionally, there is a file <code class="">/src/vite-env.d.ts</code>:</p>
<p><img src="../images/10.png" alt=""></p>
<p>For TypeScript to recognize <code class="">*.vue</code> files, we need to declare them here. Similarly, if you are using markdown files in your project, <code class="">*.md</code> files also need to be declared for TypeScript to recognize.</p>
</div>
</template>
