
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import basicUsage from './basicUsage.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<h2>Introduction</h2>
<div class="font-weight-light">An icon component for each application.</div>
<ul>
<li>Displayed at 40px x 40px by default.</li>
<li>Latest icons viewable via <a href="https://unpkg.com/@nswdoe/app-icons/index.html">Unpkg</a>.</li>
</ul>
<p>To use this component, include the following html snippets into <code class="">index.html</code> to load the icons onto the page for use in the component (configured in <code class="">doe-ui-template-v3</code> by default):</p>
<p><highlightjs language="html" code="var ajax = new XMLHttpRequest();
    ajax.onload = function (e) {
      var spriteDiv = document.createElement(&quot;div&quot;);
      spriteDiv.setAttribute(&quot;id&quot;, &quot;spriteDiv&quot;);
      spriteDiv.setAttribute(&quot;style&quot;, &quot;height: 1px; position: fixed;&quot;);
      spriteDiv.innerHTML = ajax.responseText;
    };
    ajax.open(&quot;GET&quot;, &quot;https://cdn.jsdelivr.net/npm/@nswdoe/app-icons/sprite.svg&quot;, true);
    ajax.send();"></highlightjs></p>
<div class="mt-3">
<h2>Basic usage</h2>
</div>
<div class="component-example">
    <basic-usage></basic-usage>
    <v-container>
        <v-row>
          <v-col>
            <load-vue-file component="AdsAppIcon" demo-file="basicUsage"></load-vue-file>
          </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-10 mb-3">
<h2>Props</h2>
</div>
<p><highlightjs language="javascript" code="export const adsAppIconProps = {
appName: {
  type: String,
  default: &#x27;my-nsw-gov&#x27;
},
transparent: {
  type: Boolean,
  default: false
},
size: {
  type: String,
  default: &#x27;40px&#x27;
}} as const;"></highlightjs></p>
</div>
</template>
