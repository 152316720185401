<script setup lang="ts">
import { computed, ref, watch } from "vue";
import type { Ref } from "vue";
import { useRoute } from "vue-router";
import type { RouteLocationNormalizedLoaded } from "vue-router";
import { type NavigationItem } from "@/aliases/AdsNavigationDrawer/docs/examples/ExampleNavigationDrawer";
import ExampleNavigationDrawer from "@/aliases/AdsNavigationDrawer/docs/examples/ExampleNavigationDrawer.vue";

import { useTheme } from "vuetify";

const route = useRoute();

const currentRoute: Ref<RouteLocationNormalizedLoaded> = ref({
  path: "/",
} as RouteLocationNormalizedLoaded);

watch(
  () => route.path,
  () => {
    currentRoute.value = route;
  },
);

const navItems: NavigationItem[] = [
  {
    title: "About ADS 3",
    icon: "$homeOutline",
    route: "/",
    exact: true,
    clickHandler: () => {},
  },
  {
    title: "Colour Palette",
    icon: "$paletteOutline",
    route: "/colors",
    clickHandler: () => {},
  },
  {
    title: "Typography",
    icon: "$formatText",
    route: "/typography",
    clickHandler: () => {},
  },
  {
    title: "Icons",
    icon: "$formatText",
    route: "/icons",
    clickHandler: () => {},
  },
  {
    title: "Technical Specification",
    icon: "$codeJson",
    items: [
      {
        title: "ADS 3 Overview",
        route: "/specification/overview",
      },
      {
        title: "Component Library",
        route: "/specification/library",
      },
      {
        title: "Template Project",
        route: "/specification/template",
      },
      {
        title: "Application Layout",
        route: "/specification/layout",
      },
      {
        title: "TypeScript Guide",
        route: "/specification/typescript",
      },
      {
        title: "How To Contribute",
        route: "/specification/contribute",
      },
    ],
    // route: "/specification",
    clickHandler: () => {},
  },
  {
    title: "How to start",
    icon: "$applicationBracketsOutline",
    route: "/start",
    clickHandler: () => {},
  },
  {
    title: "Components",
    icon: "$puzzleOutline",
    items: [
      {
        title: "Containment",
      },
      {
        title: "Button",
        route: "/components/AdsBtn",
      },
      {
        title: "List",
        route: "/components/AdsList",
      },
      {
        title: "Navigation",
      },
      {
        title: "App Bar",
        route: "/components/AdsAppBar",
      },
      {
        title: "Navigation Drawer",
        route: "/components/AdsNavigationDrawer",
      },
      {
        title: "Form inputs & controls",
      },
      {
        title: "Autocomplete",
        route: "/components/AdsAutocomplete",
      },
      {
        title: "Checkbox",
        route: "/components/AdsCheckbox",
      },
      {
        title: "Combobox",
        route: "/components/AdsCombobox",
      },
      {
        title: "File Input",
        route: "/components/AdsFileInput",
      },
      {
        title: "Radio Button",
        route: "/components/AdsRadio",
      },
      {
        title: "Switch",
        route: "/components/AdsSwitch",
      },
      {
        title: "Select",
        route: "/components/AdsSelect",
      },
      {
        title: "Textarea",
        route: "/components/AdsTextarea",
      },
      {
        title: "Text Field",
        route: "/components/AdsTextField",
      },
      {
        title: "Date Picker",
        route: "/components/AdsDatePicker",
      },
      {
        title: "Data & display",
      },
      {
        title: "Data Table",
        route: "/components/AdsDataTable",
      },
      {
        title: "Feedback",
      },
      {
        title: "Alert",
        route: "/components/AdsAlert",
      },
      {
        title: "Environment Info",
        route: "/components/AdsEnvironmentInfo",
      },
      {
        title: "Header",
        route: "/components/AdsHeader",
      },
      {
        title: "Bespoke",
      },
      {
        title: "App Icon",
        route: "/components/AdsAppIcon",
      },
    ],
  },
  {
    title: "Labs",
    icon: "$flaskOutline",
    items: [
      {
        title: "Chip",
        route: "/components/AdsChip",
      },
      {
        title: "Dialog",
        route: "/components/AdsDialog",
      },
      {
        title: "Initials Icon",
        route: "/components/AdsInitialsIcon",
      },
      {
        title: "Range Slider",
        route: "/components/AdsRangeSlider",
      },
      {
        title: "Slider",
        route: "/components/AdsSlider",
      },
      {
        title: "Tabs",
        route: "/components/AdsTabs",
      },
    ],
  },
  {
    title: "Changelog",
    icon: "$fileDocumentOutline",
    route: "/changelog",
  },
];
const drawer = ref(true);
const rail = ref(false);

const handleClickNavigation = () => {
  rail.value = !rail.value;
};

const theme = useTheme();

const themeIcon = computed(() => {
  return theme.global.name.value === "light"
    ? "$whiteBalanceSunny"
    : "$moonWaxingCrescent";
});

function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
}
</script>

<template>
  <v-app>
    <v-theme-provider :theme="theme.global.name.value" with-background>
      <v-app-bar>
        <template #prepend>
          <v-app-bar-nav-icon @click="handleClickNavigation" />
        </template>
        <v-app-bar-title :class="$style['app-bar--flex-vert-center']">
          <div class="d-flex align-center ga-3">
            <svg
              :class="$style.logo"
              alt="Waratah only logo"
              viewBox="0 0 261 160"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M179.829 47.002a199.218 199.218 0 0 1 4.027 38.342 115.856 115.856 0 0 1-4.735 34.757 43.12 43.12 0 0 1-14.915 21.168c13.535-.448 25.69-8.848 34.266-23.819 11.186-19.6 13.572-44.24 13.945-62.235.41-20.495-1.156-25.349-1.753-27.327-.596-1.979-1.417-2.8-3.169-2.763-3.43 0-18.009 6.57-31.618 16.315a7.92 7.92 0 0 1 3.952 5.562Z"
                fill="#D7153A"
              />
              <path
                d="M142.805 144.069a36.296 36.296 0 0 0 19.484-8.066 36.367 36.367 0 0 0 11.724-17.545 116.493 116.493 0 0 0 4.437-33.114 167.468 167.468 0 0 0-3.206-35.58c-.298-1.306-.783-3.36-2.386-3.732-1.604-.374-3.021 1.344-3.99 2.165-.969.821-24.646 21.504-34.937 47.115a61.393 61.393 0 0 0-5.294 27.178c0 5.899.522 12.507 3.467 16.24a12.697 12.697 0 0 0 10.701 5.339ZM178.525 33.227c-2.685-12.246-5.22-20.571-5.22-20.608-.56-1.494-1.38-2.651-2.536-2.838a3.985 3.985 0 0 0-3.43 1.083c-1.641 1.232-13.423 10.528-17.301 13.739a180.017 180.017 0 0 1 9.732 23.818 220.726 220.726 0 0 1 18.755-15.194ZM246.644 63.877a5.939 5.939 0 0 0-.559-3.733c-.746-1.12-1.715-1.643-4.101-1.941a236.173 236.173 0 0 0-24.646 1.269 172.783 172.783 0 0 1-1.976 23.856c5.742 2.203 11.185 4.442 17.114 7.466 11.558-14.784 13.945-24.229 14.168-26.917ZM257.494 115.061c0-1.978-1.007-3.733-4.176-5.674a194.57 194.57 0 0 0-39.076-20.571 103.542 103.542 0 0 1-11.185 31.323 55.982 55.982 0 0 1-19.352 20.72c22.856-1.568 42.096-5.115 55.929-11.798 15.884-7.616 17.972-11.461 17.86-14ZM207.01 144.555a92.057 92.057 0 0 0-13.087 1.792 67.03 67.03 0 0 1-13.721.97h-18.345a89.782 89.782 0 0 0-23.378 2.427c-.41 0-.895.261-.82.747.075.485.634.373 1.23.373.597 0 6.302-.411 9.098-.411a86.804 86.804 0 0 1 23.714 3.472 77.846 77.846 0 0 0 20.507 3.062c8.688 0 16.219-1.083 19.053-4.219a5.491 5.491 0 0 0 1.38-4.741c-.634-2.539-2.238-3.547-5.631-3.472ZM64.317 117.451c8.576 14.933 20.73 23.37 34.266 23.818a42.897 42.897 0 0 1-14.915-21.168 115.88 115.88 0 0 1-4.735-34.757c.1-12.88 1.449-25.722 4.027-38.341a7.732 7.732 0 0 1 3.952-5.563C73.116 31.21 58.724 25.2 55.294 25.125c-1.79 0-2.61.747-3.207 2.763-.596 2.016-2.125 6.832-1.715 27.328.336 17.995 2.722 42.635 13.945 62.235Z"
                fill="#D7153A"
              />
              <path
                d="M93.924 48.421c-.97-.821-2.46-2.501-3.99-2.165-1.528.336-2.125 2.352-2.386 3.733a162.127 162.127 0 0 0-3.206 35.579 115.208 115.208 0 0 0 4.437 33.115 36.029 36.029 0 0 0 11.782 17.581 35.949 35.949 0 0 0 19.612 7.917 13.111 13.111 0 0 0 7.868-2.426 17.348 17.348 0 0 1-1.939-3.248 33.866 33.866 0 0 1-2.909-14.635 74.768 74.768 0 0 1 5.742-29.045c-10.29-25.723-34.079-45.771-35.01-46.406ZM131.395 87.061a126.92 126.92 0 0 1 24.422-33.6 210.364 210.364 0 0 0-22.483-47.899c-.597-.858-1.082-1.456-1.977-1.456-.894 0-1.305.598-1.938 1.456a213.57 213.57 0 0 0-22.372 48.086 127.798 127.798 0 0 1 24.46 33.6l-.112-.187ZM112.717 24.603c-3.729-3.211-15.623-12.507-17.301-13.739a3.73 3.73 0 0 0-3.393-1.083c-1.193 0-1.976 1.344-2.535 2.838 0 0-2.573 8.362-5.22 20.608A227.013 227.013 0 0 1 102.91 48.16a174.863 174.863 0 0 1 9.807-23.557ZM30.426 90.757c6.003-2.986 11.372-5.226 17.114-7.466a172.682 172.682 0 0 1-2.088-23.67 236.484 236.484 0 0 0-24.683-1.27c-2.386.3-3.318.822-4.101 1.942a6.504 6.504 0 0 0-.56 3.734c.261 2.538 2.648 11.984 14.318 26.73ZM59.656 120.139A104.64 104.64 0 0 1 48.47 88.816a196.833 196.833 0 0 0-39.075 20.571c-3.17 2.128-4.064 3.733-4.139 5.674-.074 1.942 1.976 6.384 17.786 14 13.833 6.683 33.072 10.23 55.928 11.798a55.976 55.976 0 0 1-19.314-20.72ZM124.274 149.483a89.357 89.357 0 0 0-23.34-2.427H82.589a67.616 67.616 0 0 1-13.758-.971 91.22 91.22 0 0 0-13.05-1.792c-3.43 0-4.997.971-5.63 3.211a5.271 5.271 0 0 0 1.379 4.741c2.796 3.136 10.328 4.219 19.053 4.219a77.564 77.564 0 0 0 20.47-3.061 87.039 87.039 0 0 1 23.639-3.211c2.796 0 8.464.411 9.098.411.634 0 1.193 0 1.23-.374.037-.373-.335-.634-.746-.746Z"
                fill="#D7153A"
              />
            </svg>
            Application Design System 3.0
          </div>
        </v-app-bar-title>
        <template #append>
          <v-btn :icon="themeIcon" @click="toggleTheme" />
        </template>
      </v-app-bar>
      <example-navigation-drawer
        v-model="drawer"
        :items="navItems"
        :rail="rail"
        width="340"
      />
      <v-main class="bg-background on-background">
        <AdsHeader
          v-if="currentRoute.path !== '/'"
          :title="currentRoute.name"
          :subtitle="currentRoute.meta?.subtitle"
        />
        <v-container
          :fluid="currentRoute.path === '/'"
          :data-route="currentRoute.path"
          :class="$style.container"
        >
          <RouterView />
        </v-container>
      </v-main>
    </v-theme-provider>
  </v-app>
</template>

<style lang="scss">
code {
  background-color: #d8e1e9;
  padding: 0.15em 0.5em;
  border-radius: 4px;
  color: #476582;
  transition:
    color 0.5s,
    background-color 0.5s;
  font-size: 14px;
}
</style>

<style module lang="scss">
.container {
  &[data-route="/"] {
    padding: 0;
  }
}
.logo {
  width: 35px;
}
</style>
