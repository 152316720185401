<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field label="Label" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="With icons and placholder"
          placeholder="Placeholder"
          prepend-inner-icon="$magnify"
          append-inner-icon="$moonWaxingCrescent"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="With maxlength and hint text"
          hint="Message goes here"
          counter
          maxlength="250"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
