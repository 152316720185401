<script setup lang="ts">
import { ref } from "vue";

const drawer = ref(true);
const rail = ref(false);

const handleClickNavigation = () => {
  rail.value = !rail.value;
};
</script>

<template>
  <v-layout style="overflow: visible; border: 2px solid #f9f9f9" class="mb-6">
    <v-navigation-drawer v-model="drawer" :rail="rail">
      <v-list density="compact" nav>
        <v-list-item
          prepend-icon="$paletteOutline"
          title="My Files"
          value="myfiles"
        ></v-list-item>
        <v-list-item
          prepend-icon="$formatText"
          title="Shared with me"
          value="shared"
        ></v-list-item>
        <v-list-item
          prepend-icon="$puzzleOutline"
          title="Starred"
          value="starred"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main
      class="d-flex align-center justify-center"
      style="min-height: 500px"
    >
      <div>
        <v-btn color="primary" @click="handleClickNavigation">
          Toggle Navigation Drawer
        </v-btn>
      </div>
    </v-main>
  </v-layout>
</template>
