<template>
  <v-container>
    <v-row>
      <v-col>
        <ads-initials-icon surname="Joe" given-name="Smith" />
      </v-col>
      <v-col>
        <ads-initials-icon surname="Joe" given-name="Smith" size="50px" />
      </v-col>
      <v-col>
        <ads-initials-icon surname="Joe" given-name="Smith" size="60px" />
      </v-col>
    </v-row>
  </v-container>
</template>
