<template>
  <v-container>
    <v-responsive>
      <v-row>
        <v-col>
          <h3></h3>
        </v-col>
        <v-col>
          <h3>Right only</h3>
        </v-col>
        <v-col>
          <h3>Left only</h3>
        </v-col>
        <v-col>
          <h3>Both</h3>
        </v-col>
        <v-col>
          <h3>Icon only (no text)</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Primary</h3>
        </v-col>
        <v-col>
          <v-btn append-icon="$plus" color="primary"> Button </v-btn>
        </v-col>
        <v-col>
          <v-btn prepend-icon="$plus" color="primary"> Button </v-btn>
        </v-col>
        <v-col>
          <v-btn prepend-icon="$plus" append-icon="$plus" color="primary">
            Button
          </v-btn>
        </v-col>
        <v-col>
          <v-btn icon="$plus" color="primary" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Secondary</h3>
        </v-col>
        <v-col>
          <v-btn append-icon="$plus" color="primary" variant="outlined">
            Button
          </v-btn>
        </v-col>
        <v-col>
          <v-btn prepend-icon="$plus" color="primary" variant="outlined">
            Button
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            prepend-icon="$plus"
            append-icon="$plus"
            color="primary"
            variant="outlined"
          >
            Button
          </v-btn>
        </v-col>
        <v-col>
          <v-btn icon="$plus" color="primary" variant="outlined" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Tertiary</h3>
        </v-col>
        <v-col>
          <v-btn append-icon="$plus" color="primary" variant="text">
            Button
          </v-btn>
        </v-col>
        <v-col>
          <v-btn prepend-icon="$plus" color="primary" variant="text">
            Button
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            prepend-icon="$plus"
            append-icon="$plus"
            color="primary"
            variant="text"
          >
            Button
          </v-btn>
        </v-col>
        <v-col>
          <v-btn icon="$plus" color="primary" variant="text" />
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>
