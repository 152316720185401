<script setup lang="ts">
import { useTheme } from "vuetify";
import { computed } from "vue";
import { calculateContrastRatioAntecedent } from "./contrast";

defineOptions({
  name: "ColorPalette",
});

const theme = useTheme();

type Color = {
  name: string;
  value: string;
};

const getContrast = (color1: string, color2: string): string => {
  const colours: [string, string] = [color1, color2];

  colours.forEach((c, idx) => {
    if (c.length === 4) {
      colours[idx] = `${c}${c.slice(1, -1)}`;
    }
  });

  const antecedent = calculateContrastRatioAntecedent(...colours);
  return antecedent.toFixed(1);
};

const getContrastType = (contrast: string): string => {
  if (Number.parseFloat(contrast) >= 4.5) {
    return "success";
  } else if (Number.parseFloat(contrast) >= 3) {
    return "warning";
  } else {
    return "error";
  }
};

// Get all theme colours and pair up "[name]" and "on-[name]" colours
const groupedColors = computed(() => {
  const groupedColors: Record<string, Color[]> = {};
  const colors = theme.global.current.value.colors;
  for (const key in colors) {
    if (!key.startsWith("on-")) {
      const onKey = `on-${key}`;
      if (onKey in colors) {
        groupedColors[key] = [
          {
            name: key,
            value: colors[key],
          },
          {
            name: onKey,
            value: colors[onKey],
          },
        ];
      }
    }
  }
  return groupedColors;
});

// Get the colour value from them variables as it may have been overridden
const getColourValue = (colourName: string): string => {
  const varName = `theme-${colourName}`;
  if (varName in theme.global.current.value.variables) {
    return theme.global.current.value.variables[varName] as string;
  } else {
    return theme.global.current.value.colors[colourName];
  }
};
</script>

<template>
  <div class="markdown-body">
    <h2>Introduction</h2>
    <p>
      The colours below are self-documenting: all colours configured in the
      current theme will be displayed below.
    </p>
    <p>Toggle the theme from light to dark using the button in the app bar.</p>

    <h2>
      <span class="text-mono">"{{ theme.global.name.value }}"</span> theme
      colours
    </h2>

    <div :class="[$style.colorList, 'not-markdown']">
      <div
        v-for="(colors, _key, index) in groupedColors"
        :key="index"
        :class="[$style.colourBox, 'elevation-2 rounded-md overflow-hidden']"
      >
        <span :class="$style.contrast">
          <v-chip
            variant="flat"
            elevation="3"
            :color="
              getContrastType(getContrast(colors[0].value, colors[1].value))
            "
            :prepend-icon="`$${getContrastType(
              getContrast(colors[0].value, colors[1].value),
            )}`"
          >
            {{ getContrast(colors[0].value, colors[1].value) }} CR
          </v-chip>
        </span>
        <div
          :class="[
            $style.colour,
            `colour bg-${colors[0].name} on-${colors[0].name}`,
          ]"
        >
          {{ colors[0].name }}<br />{{ getColourValue(colors[0].name) }}
        </div>
        <div
          :class="[$style.colour, `colour text-${colors[0].name}`]"
          :style="`background: rgb(var(--v-theme-${colors[1].name}))`"
        >
          {{ colors[1].name }}<br />{{ getColourValue(colors[1].name) }}
        </div>
      </div>
    </div>

    <h2>Extend theme colours</h2>
    <p>
      It is easy to extend the theme by adding your own colours to the
      <code>theme</code> object in your Vuetify configuration. ADS 3 comes with
      two default themes: <code>light</code> and <code>dark</code>, and here is
      an example to add your own colours for the <code>light</code> theme:
    </p>
    <pre>
import { createVuetify } from 'vuetify'

export default createVuetify({
  blueprint: ads, // use ADS blueprint
  theme: {        // extend theme, here to add new colors for the light theme
    themes: {
      light: {
        colors: {
          yourColor01: '#CECECE',
          yourColor01: '#22272B',
          yourColor03: '#495054',
          ...
        }
      }
    }
  })
      </pre
    >

    <p>
      Please refer to the
      <a
        href="https://vuetifyjs.com/en/features/theme/#custom-theme-colors"
        target="_blank"
      >
        Theme configuration
      </a>
      section in the Vuetify documentation.
    </p>
  </div>
</template>

<style module lang="scss">
.colorList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.contrast {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.colourBox {
  position: relative;
}

.colour {
  text-align: center;
  font-weight: 600;
  aspect-ratio: 3 / 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
</style>
